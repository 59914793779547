// Profile.js
import React from 'react';
import Layout from '../layout/Layout'

const Profile = () => {
    return (
        <>
            <Layout>
                <div className="bg-gray-100 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-lg shadow-md h-screen overflow-auto w-full">
                        <div className="flex items-center space-x-4">
                            <img src="https://placekitten.com/100/100" alt="Profile Picture" className="w-16 h-16 rounded-full" />
                            <div>
                                <h2 className="text-2xl font-bold">John Doe</h2>
                                <p className="text-gray-600">Web Developer</p>
                            </div>
                        </div>
                        <div className="mt-8">
                            <p className="text-gray-700"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis arcu a libero rutrum, vitae rhoncus felis venenatis.</p>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default Profile;
