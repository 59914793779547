// src/components/Layout.js
import React from 'react';
import Navbar from '../component/Navbar';

const Layout = ({ children }) => {
    return (
        <div className="bg-gray-300 h-screen overflow-auto">
                <Navbar/>
            <main className="h-screen">{children}</main>
            <footer className="bg-dark-purple p-3 text-white text-center">
                © 2023 Your App Name. All rights reserved.
            </footer>
        </div>
    );
};

export default Layout;