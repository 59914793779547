import React, { useState } from "react";
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <div className="top-0 left-0 shadow-sm w-full sticky z-50 duration-1000">
                <div className="md:flex items-center justify-between py-4 md:px-10 px-7 bg-dark-purple text-white">
                    <div className="flex font-bold text-2xl cursor-pointer items-center font-[Poppins] text-light-purple">
                        <img src="https://akki.black4est.co.in/images/logo/w-logo.png" className="w-1/4 lg:w-1/3 h-full" alt="Logo"/>
                    </div>
                    <div className="text-3xl text-white absolute right-8 top-6 cursor-pointer md:hidden" onClick={toggleMenu}>
                    {isMenuOpen ? 
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                        </svg>
                    : 
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z"></path>
                        </svg>
                    }
                    </div>
                    <ul className={`md:flex lg:flex md:items-center md:pb-0 md:opacity-100 absolute md:static md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 translate-all duration-1000 ease-in bg-dark-purple ${ isMenuOpen ? "left-0 opacity-100" : "top-[-490px] opacity-0"}`}>
                        <li className="md:ml-8 md:text-xl md:my-0 my-7">
                            <Link className="text-white hover:text-white duration-500" to="/">Home</Link>
                            {/* <a  href="/"> Home </a> */}
                        </li>
                        <li className="md:ml-8 md:text-xl md:my-0 my-7">
                            <Link className="text-white hover:text-white duration-500" to="/profile">Profile</Link>
                        </li>
                        <li className="md:ml-8 md:text-xl md:my-0 my-7">
                            <a className="text-white hover:text-white duration-500" href="/about">About</a>
                        </li>
                        <li className="md:ml-8 md:text-xl md:my-0 my-7">
                            <a className="text-white hover:text-white duration-500" href="/contact"> Contact</a>
                        </li>
                        <li className="md:ml-8 md:text-xl md:my-0 my-7">
                            <a className="text-white hover:text-white duration-500" href="/meet-the-team">Meet The Team</a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};
export default Navbar;
