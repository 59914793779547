import './App.css';
import AppRoutes from './Routes';
import Home from './component/Home';

function App() {
    return (
        <>
            <AppRoutes/>
        </>
    );
}

export default App;
