import React from 'react'
import Layout from '../layout/Layout'

export default function Home() {
    return (
        <>
            <Layout>
                <div>
                    <h2 className="text-2xl font-bold">Home Page</h2>
                </div>
            </Layout>
        </>
    )
}
